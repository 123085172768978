<ng-container *ngIf="compact; else defaultTmp" [ngSwitch]="status">
    <span *ngSwitchCase="'DRAFT'" class="ml-auto">
        <far i="pencil" ngbTooltip="Draft" class="icon text-warning"></far>
    </span>
    <span *ngSwitchCase="'RX_FINALIZATION_STARTED'" class="ml-auto">
        <far i="prescription" ngbTooltip="E-prescription not finalized" class="icon text-warning"></far>
    </span>
    <span *ngSwitchCase="'RX_FINALIZATION_PENDING'" class="ml-auto">
        <far i="prescription" ngbTooltip="E-prescription pending" class="icon text-warning"></far>
    </span>
    <span *ngSwitchCase="'RX_FINALIZATION_ERROR'" class="ml-auto">
        <far i="prescription" ngbTooltip="E-prescription error" class="icon text-danger"></far>
    </span>
    <ng-container *ngSwitchDefault>
        <span *ngIf="billingStatus === 'BILLING_PENDING'" class="ml-auto">
            <far i="ellipsis-h" ngbTooltip="Pending" class="icon text-warning"></far>
        </span>
    </ng-container>
</ng-container>
<ng-template #defaultTmp [ngSwitch]="status">
    <ng-container *ngSwitchCase="'DRAFT'">
        <span style="opacity: .25" class="mx-1"> | </span>
        <span class="text-warning no-line-break"><far i="pencil"></far> Draft</span>
    </ng-container>
    <ng-container *ngSwitchCase="'RX_FINALIZATION_STARTED'">
        <span style="opacity: .25" class="mx-1"> | </span>
        <span class="text-warning no-line-break"><far i="prescription"></far> E-prescription not finalized</span>
    </ng-container>
    <ng-container *ngSwitchCase="'RX_FINALIZATION_PENDING'">
        <span style="opacity: .25" class="mx-1"> | </span>
        <span class="text-warning no-line-break"><far i="prescription"></far> E-prescription pending</span>
    </ng-container>
    <ng-container *ngSwitchCase="'RX_FINALIZATION_ERROR'">
        <span style="opacity: .25" class="mx-1"> | </span>
        <span class="text-danger no-line-break"><far i="prescription"></far> E-prescription error</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="billingStatus === 'BILLING_PENDING'">
            <span style="opacity: .25" class="mx-1"> | </span>
            <span class="text-warning no-line-break"><far i="ellipsis-h"></far> Pending</span>
        </ng-container>
    </ng-container>
</ng-template>
