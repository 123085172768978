import {Component, Input} from '@angular/core';
import {TaskStatus} from 'src/app/models/models';
import {TaskBillingStatus} from '../../../billing/visit-billing-per-day/visit-billing-day/task-billing-status';

@Component({
    selector: 'app-note-status-indication',
    templateUrl: './note-status-indication.component.html',
})
export class NoteStatusIndicationComponent {
    @Input() status: TaskStatus;
    @Input() billingStatus: TaskBillingStatus;
    @Input() compact = false;
}
